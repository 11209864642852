<template>
  <div class="w-full relative">
    <div class="swiper swiper-altoque" ref="swiper">
      <div class="swiper-wrapper" ref="wrapper">
        <slot></slot>
      </div>
    </div>
    <div ref="arrows" class="flex gap-4 items-center justify-center static mt-5 md:mt-0 md:absolute bottom-10 inset-x-0 z-10">
      <button ref="left" aria-label="Prev"
        class="bg-white text-primary-0 w-8 p-1.5 rounded-full hidden" :class="{'md:block': arrows}">
        <i v-icon:arrow-left></i>
      </button>
      <div :class="{'md:hidden': arrows}" class="!static !w-auto flex" ref="pagination"></div>
      <button ref="right" aria-label="Next"
        class="bg-white text-primary-0 w-8 p-1.5 rounded-full hidden" :class="{'md:block': arrows}">
        <i v-icon:arrow-right></i>
      </button>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: "VSliderAltoque",
  props: {
    arrows: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const $swiper = this.$refs['swiper'];
    
    new Swiper($swiper, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 600,
      navigation: {
        prevEl: this.$refs["left"],
        nextEl: this.$refs["right"],
        disabledClass: "opacity-20"
      },
      pagination: {
        type: "bullets",
        el: this.$refs["pagination"],
        bulletClass: "opacity-100 w-2 h-2 m-1 bg-neutral-40 md:bg-white rounded-full block",
        bulletActiveClass: "!bg-primary-0",
        clickable: true
      }
    });
  }
}
</script>

<style lang="scss" scoped></style>