<template>
  <div class="w-full swiper-tabs">
    <div class="flex justify-center">
      <div class="swiper-pagination" ref="pagination">
        <div class="swiper-pagination__button-list">
          <div class="swiper-pagination__pill" ref="bullte-active"></div>
          <button v-for="(item, index) in items" :key="index" @click="goToSlide(index)" :ref="`bullte-${index}`" :class="[
            'swiper-pagination__button-item whitespace-nowrap',
            activeIndex === index
              ? 'text-primary-0 active'
              : 'text-blue-dark-10 hover:text-gray-700',
          ]">
            {{ item.title }}
          </button>
        </div>
      </div>
    </div>
    <div v-show="isMobile" v-if="isMulti" class="flex flex-col gap-5">
      <div class="flex flex-col" v-for="(item, index) in items" :key="`accordion-${index}-${_uid}`">
        <div class="flex justify-between gap-4 ">
          <h3 class="text-[20px] leading-[1.1] font-terpel-bold">{{ item.title }}</h3>
          <button @click="goToSlide(index)"
            class="transition-all duration-500 flex w-5.5 h-5.5 rounded-full border-2 border-current text-primary-0"
            :class="{ 'rotate-180': !(activeIndex == index) }"><i v-icon:arrow-up class="w-4 h-4 m-auto"></i></button>
        </div>
        <div class="grid overflow-hidden items-start transition-all duration-500"
          :class="activeIndex == index ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'">
          <div class="overflow-hidden">
            <div class="grid grid-cols-2 gap-4 mt-5.5">
              <figure class="bg-white relative rounded-2xl overflow-hidden shadow" v-for="(image, i) in item.images"
                :key="`image-${index}-${i}-${_uid}`">
                <img class="w-full aspect-[408/338]" :src="image.url" :alt="image.alt">
                <figcaption v-if="image.title" class=" border-t border-[#E2E5EF] w-full p-3 font-terpel-bold text-[11px] leading-[1.1] text-center">
                  {{ image.title }}
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMulti" v-show="!isMobile" class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in items" :key="`item-${index}-${_uid}`">
          <VPaginationGrid :items="item.images">
            <template #default="{ item }">
              <figure class="relative rounded-2xl overflow-hidden shadow bg-white" :key="item.id">
                <img class="w-full aspect-[408/338]" :src="item.url" :alt="item.alt">
                <figcaption
                  class="border-t border-[#E2E5EF] w-full px-8 py-9 leading-[1.1] text-[23px] font-terpel-bold text-center">{{
                  item.title }}
                </figcaption>
              </figure>
            </template>
          </VPaginationGrid>
        </div>
      </div>
    </div>
    <div v-else class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in items" :key="`item-${index}-${_uid}`">
          <img :src="item.images[0].url" :alt="item.images[0].alt || item.title" class="mx-auto" />
          <h3 class="text-xl text-center font-terpel-bold md:hidden mt-5">
            {{ item.title }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VPaginationGrid from "../VPagination/VPaginationGrid.vue";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "VSliderTabs",
  props: {
    items: Array,
    require: true
  },
  data() {
    return {
      activeIndex: 0,
      isMobile: null
    };
  },
  components: {
    VPaginationGrid
  },
  computed: {
    isMulti() {
      return Boolean(this.items.find(item => item.images.length > 1));
    }
  },
  mounted() {
    this.isMobile = this.onIsMobile();
    new Swiper(this.$refs["swiper"], {
      slidesPerView: 1.5,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
      },
      speed: 600,
      on: {
        slideChange: () => {
          const index = this.$refs["swiper"].swiper.activeIndex;
          this.activeIndex = index;
          this.onBullteActive(index);
        },
      },
    });
    this.onBullteActive(this.activeIndex);
    window.addEventListener("resize", () => {
      this.isMobile = this.onIsMobile();
      if (!this.isMobile)
        this.onBullteActive(this.activeIndex);
    });
  },
  methods: {
    onIsMobile() {
      return window.innerWidth < 768;
    },
    goToSlide(index) {
      if (this.$refs["swiper"]) {
        this.$refs["swiper"].swiper.slideTo(index);
      }
    },
    onBullteActive(index) {
      const $bullte = this.$refs[`bullte-${index}`][0];
      const x = $bullte.offsetLeft;
      const y = $bullte.offsetTop;
      const width = $bullte.offsetWidth;

      const $active = this.$refs[`bullte-active`];

      $active.style.width = `${width}px`;
      $active.style.transform = `translate(${x}px, ${y}px)`;
    }
  },
};
</script>
