<template>
  <div class="relative">
    <div class="swiper" ref="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">

        <div v-for="(item, i) in items" :key="`slide-mobile-${i}-${_uid}`" class="swiper-slide">
          <div class="relative">
            <div :class="expanded
              ? 'min-h-screen py-24 md:py-28'
              : 'py-12 md:py-24 min-h-[400px] md:min-h-[600px]'"
              class="relative w-full aspect-[450/600] sm:aspect-auto pb-24 md:py-28 px-8 md:px-4 flex flex-col justify-end">
              <div v-if="item.image_mobil || item.image" class="absolute inset-0">
                <figure class="w-full h-full">
                  <img class="w-full h-full object-center object-cover" :src="item.image" alt="" />
                </figure>
                <div
                  class="bg-gradient-to-t lg:bg-gradient-to-r from-blue-dark-10 via-blue-dark-10/[0.4] absolute inset-0 opacity-60 z-10">
                </div>
              </div>
              <div class="max-w-7xl w-full mx-auto min-h-[380px] flex flex-col">
                <div
                  class="relative z-10 flex flex-col gap-4 md:gap-8 max-w-[550px] mx-auto md:mx-0 text-white mt-auto">
                  <h3 v-if="item.title" v-html="item.title"
                    class="font-bold text-5xl lg:text-6xl max-w-[10em] leading-none"></h3>
                  <div v-if="item.description" v-html="item.description" :title="item.description"
                    class="text-base md:text-xl xl:text-2xl-alt font-light line-clamp-4"></div>
                  <div v-if="item.link">
                    <a :href="item.link"
                      class="text-sm md:text-xl text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary">
                      {{ onLabel(item) || btnText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>
    </div>
    <div v-if="expanded" class="px-6 md:px-4 absolute inset-x-0 bottom-8 md:bottom-28 pointer-events-none z-20 hidden md:block">
      <div class="max-w-7xl ml-auto md:h-24 text-primary-0 flex pointer-events-none">
        <div class="swiper !mr-0 pointer-events-auto" ref="pagination">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">

            <div v-for="(item, i) in items" :key="`card-${i}-${_uid}`" class="swiper-slide">
              <div
                class="cursor-pointer flex justify-end items-center gap-4 transition md:scale-75 origin-right">
                <span class="hidden md:inline-block" v-html="item.title"></span>
                <span
                  class="block w-7 border-b-2 border-current transition-[width]"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
import { Pagination as SwiperPagination, Thumbs, Autoplay as SwiperAutoplay, EffectFade } from 'swiper/modules';


export default {
  name: "VBannerPrimary",
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: "Conoce más",
    },
  },
  data() {
    return {
      
    };
  },
  mounted() {
    const $Swiper = this.$refs["swiper"];
    const $Pagination = this.$refs["pagination"];
    if ($Swiper && $Pagination) {
      const $elPagination = $Swiper.querySelector('.swiper-pagination');

      const $pagination = new Swiper($Pagination, {
        speed: 800,
        direction: "vertical",
        slidesPerView: 3,
        slidesPerGroup: 1,
        centeredSlides: true
        
      });
      new Swiper($Swiper, {
        modules: [SwiperPagination, SwiperAutoplay, Thumbs, EffectFade],
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        speed: 800,
        autoplay: {
          delay: 9000
        },
        pagination: {
          el: $elPagination,
          type: 'bullets',
          clickable: true
        },
        thumbs: {
          swiper: $pagination,
          autoScrollOffset: 0.5
        }
      });
    }
  },
  methods: {
    onLabel(item) {
      const success =
        item.cta_label && Object.keys(item.cta_label).includes("text");
      return success ? item.cta_label.text : "";
    },
  },
};
</script>
