<template>
  <section class="pt-4 pb-12 px-4 md:px-0 md:py-10 relative xl:aspect-[1728/775] flex flex-col justify-center">
    <div class="inset-0 absolute z-10 pointer-events-none">
      <div class="swiper swiper-altoque w-full h-full" ref="bg-swiper">
        <div class="swiper-wrapper" ref="wrapper">
          <div class="swiper-slide" v-for="(item, index) in items" :key="`${index}`">
            <div class="banner-main__content">
              <div class="absolute inset-0">
                <figure class="w-full h-full">
                  <img class="banner-main__imagen" :src="item.image" :alt="item.title" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative z-20 flex flex-col gap-[51px]">
      <div class="banner-breadcrumb block-section-wrapper !hidden md:!block" ref="breadcrumb">
      </div>
      <div class="swiper swiper-altoque w-full" ref="swiper">
        <div class="swiper-wrapper" ref="wrapper">
          <div class="swiper-slide" v-for="(item, index) in items" :key="`${index}`">
            <div class="banner-main__content px-4">
              <div class="block-section-wrapper">
                <div class="banner-main__info">
                  <div class="banner-main__info--heading">
                    <i class="banner-main__icon" v-if="item.icon" v-icon:[item.icon]></i>
                    <h2 v-if="item.title" class="banner-main__title">
                      {{ item.title }}
                    </h2>
                  </div>
                  <div v-if="item.description" class="banner-main__description" v-html="item.description">
                  </div>
                  <div v-if="item.cta.url" class="banner-main__button">
                    <a :href="item.cta.url" class="button button-primary button-rounded has-icon-right">{{
                      item.cta.label }}
                      <i class="icon" v-icon:arrow-alt-right></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination flex justify-center !-bottom-8 md:hidden" ref="pagination"></div>
    <div ref="arrows"
      class="hidden md:flex gap-4 items-center justify-center static mt-5 md:mt-0 md:absolute bottom-[38px] inset-x-0 z-30">
      <button ref="left" aria-label="Prev" class="bg-white text-primary-0 w-8 p-1.5 rounded-full">
        <i v-icon:arrow-left></i>
      </button>
      <button ref="right" aria-label="Next" class="bg-white text-primary-0 w-8 p-1.5 rounded-full">
        <i v-icon:arrow-right></i>
      </button>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: "VSliderAltoque",
  props: {
    items: {
      type: Array,
      require: true
    }
  },
  mounted() {

    const $breadcrumb = document.getElementById("BreadcrumbNav");
    if ($breadcrumb) {
      const $refBreadcrumb = this.$refs["breadcrumb"];
      $refBreadcrumb.insertBefore($breadcrumb, null);
    }



    const $swiper = this.$refs['swiper'];
    const $bgSwiper = this.$refs['bg-swiper'];
    const $swiperControl = new Swiper($bgSwiper, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 600,
      effect: "fade",
    });
    new Swiper($swiper, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 600,
      controller: {
        control: $swiperControl
      },
      navigation: {
        prevEl: this.$refs["left"],
        nextEl: this.$refs["right"],
        disabledClass: "opacity-20"
      },
      pagination: {
        type: "bullets",
        el: this.$refs["pagination"],
        bulletClass: "opacity-100 w-2 h-2 m-1 bg-neutral-40 md:bg-white rounded-full block",
        bulletActiveClass: "!bg-primary-0",
        clickable: true
      }
    });

  }
}
</script>