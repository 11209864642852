import DocumentEmbed from './DocumentEmbed/DocumentEmbed.vue';
import DocumentViewer from './DocumentViewer/DocumentViewer.vue';
import BvcStats from './BvcStats/BvcStats.vue';
import VTabs from './CustomTags/Tabs/VTabs.vue';
import TabItemList from './CustomTags/Tabs/TabItemList.vue';
import TabContent from './CustomTags/Tabs/TabContent.vue';
import VAccordion from './CustomTags/Accordion/VAccordion.vue';
import AccordionContent from './CustomTags/Accordion/AccordionContent.vue';
import VMessage from './VMessage/VMessage.vue';
import VSelect from './Select/VSelect.vue';
import VSelectPrimary from './Select/VSelectPrimary.vue';
import ModalImages from './Modals/ModalImages.vue';
import ModalSingleImage from './Modals/ModalSingleImage.vue';
import BaseCarousel from './Carousel/BaseCarousel.vue';
import BannerCarousel from './Carousel/BannerCarousel.vue';
import CardLandscapeCarousel from './Carousel/CardLandscapeCarousel.vue';
import PromoCardCarousel from './Carousel/PromoCardCarousel.vue';
import SliderBannerCarousel from './Carousel/SliderBannerCarousel.vue';
import CardServicesCarousel from './Carousel/CardServicesCarousel.vue';
import CardImageCarousel from './Carousel/CardImageCarousel.vue';
import FullCalendar from './FullCalendar/FullCalendar.vue';
import TabsAsync from './TabsAsync/TabsAsync.vue';
import TabItemListAsync from './TabsAsync/TabItemList.vue';
import MediaLibrary from './MediaLibrary/MediaLibrary.vue';
import MediaLibraryTab from './MediaLibrary/MediaLibraryTab.vue';

import VTimeline from './Timeline/VTimeline.vue';
import VIndicator from './Indicator/VIndicator.vue'
import GalleryVideoImage from './GalleryVideoImage/GalleryVideoImage.vue';
import GalleryVideoImageModal from './GalleryVideoImage/GalleryVideoImageModal.vue';

import TheHeader from './Header/TheHeader.vue';
import HeaderSearch from './Header/HeaderSearch.vue';
import HeaderMegamenuDesktop from './Header/HeaderMegamenuDesktop.vue';
import HeaderMegamenuMobile from './Header/HeaderMegamenuMobile.vue';

import VPaginator from './Paginator/VPaginator.vue';
import PaginatorPromoCard from './Paginator/PaginatorPromoCard.vue';
import PaginatorCardLandscape from './Paginator/PaginatorCardLandscape.vue';
import PaginatorTable from './Paginator/PaginatorTable.vue';
import CertDownloadForm from './CertDownloadForm/CertDownloadForm.vue';

import CustomForm from './Forms/CustomForm.vue';
import ContactForm from './Forms/ContactForm.vue';
import CustomSelect from './Inputs/CustomSelect.vue';
import VTextField from './Inputs/VTextField.vue';
import RadioButton from './RadioButton/RadioButton.vue';
import CheckboxButton from './CheckboxButton/CheckboxButton.vue';
import HabeasData from './HabeasData/HabeasData.vue';
import VScroll from './VScroll/VScroll.vue';
import VLoader from './VLoader/VLoader.vue';
import VChatbot from './VChatbot/VChatbot.vue';
import VDinamicTable from './VDinamicTable/VDinamicTable.vue';
import InteractiveGraphic from './InteractiveGraphic/InteractiveGraphic.vue';

import { Glide, GlideSlide } from 'vue-glide-js';
import { Calendar, DatePicker } from 'v-calendar';
import { Swiper, SwiperSlide } from 'swiper-vue2';

import VGrid from './VGrid/VGrid.vue';
import VGridCardFile from './VGrid/VGridCardFile.vue';
import VGridCardVideo from './VPanelVideos/VGridCardVideo.vue';
import VSelectGrid from './VGrid/VSelectGrid.vue';

import VBannerPrimary from './VSliders/VBannerPrimary.vue';
import VSlidePromo from './VSliders/VSlidePromo.vue';
import VSliderTabs from './VSliders/VSliderTabs.vue';
import VSliderFlexcards from './VSliders/VSliderFlexcards.vue';
import VSliderAltoque from './VSliders/VSliderAltoque.vue';
import VCarouselPromo from './VSliders/VCarouselPromo.vue';
import VBannerVideoGallery from './VSliders/VBannerVideoGallery.vue';
import VBannerAltoque from './VSliders/VBannerAltoque.vue';

import VHeader from './VHeader/VHeader.vue';
import VHeaderSearch from './VHeader/VHeaderSearch.vue';
import VHeaderMegamenuDesktop from './VHeader/VHeaderMegamenuDesktop.vue';
import VHeaderMegamenuMobile from './VHeader/VHeaderMegamenuMobile.vue';

import MapViewer from './Maps/VMap.vue';


export default {
  [Glide.name]: Glide,
  [GlideSlide.name]: GlideSlide,
  InteractiveGraphic,
  Calendar,
  DatePicker,
  Swiper,
  SwiperSlide,
  HeaderSearch,
  VMessage,
  DocumentEmbed,
  DocumentViewer,
  BvcStats,
  VTabs,
  TabItemList,
  TabContent,
  VAccordion,
  AccordionContent,
  FullCalendar,
  ModalImages,
  ModalSingleImage,
  TheHeader,
  HeaderMegamenuDesktop,
  HeaderMegamenuMobile,
  BaseCarousel,
  BannerCarousel,
  VTimeline,
  SliderBannerCarousel,
  CardLandscapeCarousel,
  PromoCardCarousel,
  TabsAsync,
  TabItemListAsync,
  VScroll,
  CardServicesCarousel,
  CardImageCarousel,
  VPaginator,
  PaginatorPromoCard,
  PaginatorCardLandscape,
  MediaLibrary,
  MediaLibraryTab,
  CustomForm,
  ContactForm,
  VSelect,
  VSelectPrimary,
  CustomSelect,
  VTextField,
  RadioButton,
  CheckboxButton,
  HabeasData,
  MapViewer,
  CertDownloadForm,
  VIndicator,
  GalleryVideoImage,
  GalleryVideoImageModal,
  PaginatorTable,
  VLoader,
  VChatbot,
  VGrid,
  VSelectGrid,
  VGridCardFile,
  VBannerPrimary,
  VSlidePromo,
  VCarouselPromo,
  VHeader,
  VHeaderMegamenuDesktop,
  VHeaderSearch,
  VHeaderMegamenuMobile,
  VBannerVideoGallery,
  VGridCardVideo,
  VDinamicTable,
  VSliderTabs,
  VSliderFlexcards,
  VSliderAltoque,
  VBannerAltoque
}