<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-[30px] mt-20 pb-2" :class="items.length > 3 ? 'grid-rows-3 lg:grid-rows-2' : ''">
      <slot v-for="(item, index) in list" :item="item" :id="`item-${index}-${item.id}-${_uid}`"></slot>
      <div v-for="i in (6 - list.length)"></div>
    </div>
    <VPagination class="mx-auto" :pages="pagination.pages" v-model="current"></VPagination>
  </div>
</template>

<script>
import VPagination from './VPagination.vue';
export default {
  name: "VPaginationGrid",
  components: {
    VPagination
  },
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      current: 1,
      perPage: 6,
    }
  },
  computed: {
    pagination() {
      let start = (this.current - 1) * this.perPage;
      let end = start + this.perPage;
      let pages = Math.ceil(this.items.length / this.perPage);
      return {
        pages,
        start,
        end
      }
    },
    list() {
      const result = this.items.map((item, index) => ({ id: index + 1, ...item }));
      return result.slice(this.pagination.start, this.pagination.end);
    }
  }

}
</script>

<style lang="scss" scoped></style>