<template>
  <component class="h-full flex" :is="config.title_link ? 'a' : 'div'" v-bind="config.title_link ? {href: config.title_link} : {}">
    <article class="card-promo bg-neutral-10">
      <div class="card-promo__a gap-3">
        <div class="relative flex flex-col">
          <figure>
            <img :src="imageUrl" alt="promo-card" class="card-promo__image" :class="[{ 'shadow': !config.image_url}, ratioImg]" />
          </figure>
          <a v-if="config.cta_url" :href="config.cta_url" class="bg-primary-0 card-promo__button">
            <i class="card-promo__icon" v-icon:plus></i>
          </a>
        </div>
        <div class="card-promo__content" v-if="hasData">
          <h5 v-if="config.title">
            {{ config.title }}
          </h5>
          <div class="text-neutral-80" v-if="config.description">
            <p v-html="config.description"></p>
          </div>
          <div class="card-promo__content-date text-neutral-60" v-if="config.date">
            <p>{{ config.date }}</p>
          </div>
        </div>
      </div>
    </article>
  </component>
</template>

<script>
  import dummyImage from '@public/images/dummy-image.jpg';

  export default {
    name: "PaginatorPromoCard",
    props: {
      config: {
        type: Object,
        required: true
      },
      ratioImg: {
        type: String
      }
    },
    computed: {
      hasData() {
        const { title, description, date } = this.config;
        return title || description || date;
      },
      imageUrl() {
        return this.config.image_url ?? dummyImage;
      }
    },
  };
</script>