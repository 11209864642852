<template>
  <div class="w-full">
    <div class="swiper swiper-flexcards" ref="swiper">
      <div ref="slot">
        <slot></slot>
      </div>
      <div class="swiper-wrapper" ref="wrapper">
      </div>
    </div>
    <div ref="arrows" class="flex gap-4 self-center">
      <button ref="left" aria-label="Prev" class="text-primary-0 w-8 p-1 rounded-full border-2 border-primary-0">
        <i v-icon:arrow-left></i>
      </button>
      <button ref="right" aria-label="Prev" class="text-primary-0 w-8 p-1 rounded-full border-2 border-primary-0">
        <i v-icon:arrow-right></i>
      </button>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: "VSliderFlexcards",
  mounted() {
    const $swiper = this.$refs['swiper'];
    const $slots = this.$refs['slot'];
    const $wrapper = this.$refs['wrapper'];

    for (let item of $slots.children) {
      const wrapper = document.createElement("div");
      wrapper.classList.add("swiper-slide", "!h-auto");
      const el = item.cloneNode(true);
      el.classList.add("h-full")
      wrapper.append(el);
      $wrapper.append(wrapper);
    }
    $slots.remove();

    new Swiper($swiper, {
      navigation: {
        prevEl: this.$refs["left"],
        nextEl: this.$refs["right"],
        disabledClass: "opacity-20"
      },
      breakpoints: {
        900: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 32,
        },
        1180: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 32,
        }
      },
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 16,
      speed: 600
    });

    const $arrows = this.$refs["arrows"];
    const block = $swiper.closest(".altoque");
    const navigation = block.querySelector(".sp-navigation");
    navigation.insertBefore($arrows, null);
  }
}
</script>

<style lang="scss" scoped></style>